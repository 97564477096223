<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="w-100 col-lg-7 d-lg-flex brand-logo">
        <vuexy-logo />
        <!-- <h2 class="brand-text text-primary ml-1">
          #KitaKerja
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-0"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-3"
      >
        <b-row class="mt-5 mt-lg-0">
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto pt-5 pt-lg-0"
          >
            <b-card-title class="mb-1 pt-xs-100">
              Daftar Sekarang
            </b-card-title>
            <b-card-text class="mb-2">
            Satu langkah lagi untuk menjadi bagian dari Kitakerja. Lengkapi profil anda untuk mendaftar.
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="registerForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="register"
              >
                <b-row>
                  <b-col sm="6">
                    <b-form-group
                      label="Daftar Sebagai"
                      label-for="register-as"
                    >
                      <template v-slot:label>
                        Daftar Sebagai <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Register Sebagai"
                        vid="register-as"
                        rules="required"
                      >
                        <v-select
                          id="register-as"
                          v-model="registerAs"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="registerOption"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <b-form-group
                      label="Nama Lengkap"
                      label-for="register-name"
                    >
                      <template v-slot:label>
                        Nama Lengkap <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Nama Lengkap"
                        vid="name"
                        rules="required"
                      >
                        <b-form-input
                          id="register-name"
                          v-model="userFullName"
                          name="register-name"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-form-group
                      label="HP / Telepon"
                      label-for="register-handphone"
                    >
                      <template v-slot:label>
                        HP / Telepon <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="HP / Telepon"
                        vid="handphone"
                        rules="required"
                      >
                        <cleave
                          id="register-handphone"
                          v-model="handphone"
                          name="register-handphone"
                          :raw="false"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                          :options="clevePhone"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      label="Tanggal Lahir"
                      label-for="register-dob"
                    >
                      <template v-slot:label>
                        Tanggal Lahir <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Tanggal Lahir"
                        vid="register-dob"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="dob"
                          class="form-control"
                          name="register-dob"
                          placeholder="Tanggal Lahir"
                          :class="(errorsValidation && errorsValidation.dob && errorsValidation.dob.length > 0) ? 'is-invalid': ''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-form-group
                      label="Jenis Kelamin"
                      label-for="register-gender"
                    >
                      <template v-slot:label>
                        Jenis Kelamin <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Jenis Kelamin"
                        vid="register-gender"
                        rules="required"
                      >
                        <v-select
                          id="register-gender"
                          v-model="gender"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="genderOption"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      label-for="pendidikanList"
                      label="Pendidikan Saat Ini"
                    >
                      <template v-slot:label>
                        Pendidikan Saat Ini <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Pendidikan Saat Ini"
                        vid="register-pendidikan"
                        rules="required"
                      >
                        <v-select
                          id="pendidikanList"
                          v-model="pendidikanTerakhir"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="pendidikanOption.options"
                          :class="(errorsValidation && errorsValidation.status_pekerjaan && errorsValidation.status_pekerjaan.value && errorsValidation.status_pekerjaan.value.length > 0) ? 'is-invalid': ''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <b-form-group
                      label-for="provinsiList"
                      label="Provinsi"
                    >
                      <template v-slot:label>
                        Provinsi <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Provinsi"
                        vid="register-provinsi"
                        rules="required"
                      >
                        <v-select
                          id="provinsiList"
                          v-model="provinsi"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="provinsiOption.options"
                          @input="(val) => changeKabKota(val)"
                          :class="(errorsValidation && errorsValidation.provinsi && errorsValidation.provinsi.value && errorsValidation.provinsi.value.length > 0) ? 'is-invalid': ''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      label-for="kabKotaList"
                      label="Kabupaten / Kota"
                    >
                      <template v-slot:label>
                        Kabupaten / Kota <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Kabupaten / Kota"
                        vid="register-kabkota"
                        rules="required"
                      >
                        <v-select
                          id="kabKotaList"
                          v-model="kabKota"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="kabkotaOption.options"
                          :class="(errorsValidation && errorsValidation.kab_kota && errorsValidation.kab_kota.value && errorsValidation.kab_kota.value.length > 0) ? 'is-invalid': ''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <b-form-group
                      label-for="genderList"
                      label="Ragam Disabilitas"
                    >
                      <template v-slot:label>
                        Ragam Disabilitas <span class="text-danger">*</span>
                      </template>
                      <v-select
                        id="genderList"
                        v-model="disabilitas"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="disabilitasOption.options"
                        :class="(errorsValidation && errorsValidation.disabilitas && errorsValidation.disabilitas.value && errorsValidation.disabilitas.value.length > 0) ? 'is-invalid': ''"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      label-for="statusPekerjaanList"
                      label="Status Pekerjaan"
                    >
                      <template v-slot:label>
                        Status Pekerjaan <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Status Pekerjaan"
                        vid="register-statuspekerjaan"
                        rules="required"
                      >
                        <v-select
                          id="statusPekerjaanList"
                          v-model="statusPekerjaan"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusPekerjaanOption.options"
                          :class="(errorsValidation && errorsValidation.status_pekerjaan && errorsValidation.status_pekerjaan.value && errorsValidation.status_pekerjaan.value.length > 0) ? 'is-invalid': ''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <b-form-group
                      label-for="statusPernikahanList"
                      label="Status Pernikahan"
                    >
                      <template v-slot:label>
                        Status Pernikahan <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Status Pernikahan"
                        vid="register-statuspernikahan"
                        rules="required"
                      >
                        <v-select
                          id="statusPernikahanList"
                          v-model="statusPernikahan"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusPernikahanOption.options"
                          :class="(errorsValidation && errorsValidation.status_pernikahan && errorsValidation.status_pernikahan.value && errorsValidation.status_pernikahan.value.length > 0) ? 'is-invalid': ''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-form-group
                      label="Email"
                      label-for="register-email"
                    >
                      <template v-slot:label>
                        Alamat Email <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        vid="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="register-email"
                          v-model="userEmail"
                          name="register-email"
                          :state="errors.length > 0 ? false:null"
                          :class="(errorsValidation && errorsValidation.email && errorsValidation.email.length > 0) ? 'is-invalid': ''"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <!-- password -->
                    <b-form-group
                      label-for="register-password"
                      label="Password"
                    >
                      <template v-slot:label>
                        Password <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        vid="password"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="!passwordValid ? 'is-invalid':null"
                        >
                          <b-form-input
                            id="register-password"
                            v-model="password"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            :state="!passwordValid ? false:null"
                            name="register-password"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger" v-if="!passwordValid">Password harus mengandung huruf besar, huruf kecil, angka, dan kombinasi karakter dan memiliki minimal 8 karakter.</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group>
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Saya setuju untuk mengikuti
                    <b-link v-b-modal.modal-default>syarat dan kondisi kebijakan data privasi</b-link>
                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  Daftar Sekarang
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <span>Sudah memiliki akun?</span>
              <b-link :to="{name:'auth-login'}">
                <span>&nbsp;Masuk Sekarang</span>
              </b-link>
            </p>

            <b-modal
              id="modal-default"
              ok-only
              ok-title="Saya Setuju"
              centered
              size="lg"
              title="Pernyataan persetujuan penyimpanan, penggunaan data"
            >
              <b-card-text>
                <p>Yayasan Plan International Indonesia adalah Lembaga yang memiliki komitmen utama dalam melindungi anak-anak, kaum muda dari segala bentuk kekerasan. Kami juga mendorong terpenuhinya hak-hak anak dan kesetaraan perempuan.Kami akan mengggunakan sumber daya yang kami miliki secara wajar untuk memastikan semua proses bisnis pekerjaan yang lakukan aman termasuk dalam pengumpulan, pengolahan, penyimpanan,penggunaan dan distribusi data.
Kebijakan Perlindungan data kami menegaskan, bahwa kami tidak akan memberikan ruang dan tidak mentolersansi segala bentuk tindakan, disengaja atau tidak disengaja yang menyebabkan rusak, hilang,penyalahgunaan,pengalihan data dan distribusi data yang tidak sesuai kebijakan.</p>
                <p> Kami memiliki kewajiban terhadap data-data partisipan Proyek yang kami simpan, sebagai berikut:</p>
                <ol>
                  <li>Dilindugi dengan system Perlindungan data secara a baik (enkripsi dan mekanisme akses);</li>
                  <li>Sesuai dengan kebijakan Perlindungan data YPII;</li>
                  <li>Mengikuti peraturan perundangan yang berlaku di Indonesia;</li>
                  <li>endapat persetujuan mengenai pengumpulan, penggunaan, distribusi dari pemilik data;</li>
                  <li>Tidak mempublikasikan data pribadi yang sensitive yang bisa digunakan untuk mengidentifikasi lokasi, dan profile pemilik data;</li>
                  <li>Hanya digunakan sesuai kesepakatan dalam persetujuan, dan penggunaan diluar tujuan awal harus mendapat ulang dari pemilik data;</li>
                  <li>Setiap data yang tidak lagi digunakan sesuai durasi kegiatan maka data akan dikembalikan kepada pemilik data atau dihancurkan sesuai ketentuan dalam kebijakan Perlindungan data YPII.</li>
                </ol>
                <p>Setiap Pemilik data memiliki Hak:</p>
                <ol>
                  <li>Memastikan penyimpanan, penggunaan, dan distribusi data sesuai ketentuan;</li>
                  <li>Membatalkan persetujuan, meski diawal sudah memberikan persetujuan;</li>
                  <li>Meminta sebagian atau beberapa data untuk dianonimkan;</li>
                  <li>Membawa ke proses hukum di negara Republik Indonesia jika terbukti terjadi pelanggaran kebijakan atau tidak sesuai perundangan di negara RI;</li>
                </ol>
                <p>Setelah membaca hal diatas, dengan penuh kesadaran dan pemahaman saya menyatakan setuju data-data saya dipergunakan untuk kepentingan Proyek Kitakerja</p>
                <p>Untuk mengetahui lebih detail  isi kebijakan Perlindungan data privasi YPII silahkan <a href="PII-Data_Privacy_Policy-Final-IO-Eng-Jun18.pdf">unduh disini</a></p>
              </b-card-text>
            </b-modal>
            <!-- <small>
              Situs ini dilindungi oleh Google ReCaptcha
<a href="https://policies.google.com/privacy">Kebijakan pribadi</a> dan
<a href="https://policies.google.comx/terms">Persyaratan Layanan</a>.
            </small> -->

            <!-- divider -->
            <!-- <div class="divider my-2">
              <div class="divider-text">
                or
              </div>
            </div> -->
            <!-- divider -->
            <!-- <div class="divider my-2">
              <div class="divider-text">
                or
              </div>
            </div>

            <div class="auth-footer-btn d-flex justify-content-center">
              <b-button
                variant="facebook"
                href="javascript:void(0)"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <b-button
                variant="twitter"
                href="javascript:void(0)"
              >
                <feather-icon icon="TwitterIcon" />
              </b-button>
              <b-button
                variant="google"
                href="javascript:void(0)"
              >
                <feather-icon icon="MailIcon" />
              </b-button>
              <b-button
                variant="github"
                href="javascript:void(0)"
              >
                <feather-icon icon="GithubIcon" />
              </b-button>
            </div> -->
          </b-col>
        </b-row>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@/views/layouts/components/LogoDouble.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BModal, VBModal,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import router from '@/router'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import {
  getPropinsi,
  getKabKota,
  getJenisPendidikan,
  getStatusPekerjaan,
  getStatusPernikahan,
  getDisabilitas,
} from '@/connection-api/master'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import {
  Authregister,
} from '@/connection-api/authentication'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
    BModal,
    flatPickr,
    Cleave,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      errorsValidation: [],
      status: '',
      userFullName: '',
      handphone: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      dob: '',
      gender: 'Laki-laki',
      pendidikanTerakhir: '',
      provinsi: '',
      kabKota: '',
      statusPernikahan: '',
      statusPekerjaan: '',
      disabilitas: '',
      genderOption: ['Laki-laki', 'Perempuan', 'Tidak bersedia menjawab'],
      registerAs: '',
      // registerOption: ['Youth', 'Trainer/Mentor', 'Student/Murid', 'Teacher/Guru', 'Others/Lainnya'],
      registerOption: ['Youth', 'Trainer/Mentor', 'Student/Murid', 'Teacher/Guru', 'Others/Lainnya'],
      applicationToken: null,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'ID',
      },
      recaptcha: '',
      disabilitasOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Disabilitas --' },
        ],
      },
      provinsiOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih --' },
        ],
      },
      kabkotaOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih --' },
        ],
      },
      pendidikanOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih --' },
        ],
      },
      statusPekerjaanOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih --' },
        ],
      },
      statusPernikahanOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih --' },
        ],
      },
    }
  },
  async mounted() {
    this.recaptchaReload()
    this.applicationToken = router.currentRoute.params.token
    const responsePropinsi = await getPropinsi()
    this.mappingSelectOption(responsePropinsi.data, this.provinsiOption.options)

    const responseJenisPendidikan = await getJenisPendidikan()
    this.mappingSelectOption(responseJenisPendidikan.data, this.pendidikanOption.options)

    const responseStatusPekerjaan = await getStatusPekerjaan()
    this.mappingSelectOption(responseStatusPekerjaan.data, this.statusPekerjaanOption.options)

    const responseStatusPernikahan = await getStatusPernikahan()
    this.mappingSelectOption(responseStatusPernikahan.data, this.statusPernikahanOption.options)

    const responseJenisDisabilitas = await getDisabilitas()
    this.mappingSelectOption(responseJenisDisabilitas.data, this.disabilitasOption.options)
  },
  computed: {
    passwordValid() {
      // validasi password
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      if (this.password.length < 8) {
        return false
      }
      return regex.test(this.password)
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async changeKabKota(propinsiId) {
      this.kabkotaOption = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kabupaten Kota --' },
        ],
      }
      const response = await getKabKota(propinsiId.value)
      this.mappingSelectOption(response.data, this.kabkotaOption.options)
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    makeToast(variant = null, message = null, title = null) {
      // this.$bvToast.toast(message, {
      //   title: 'Kesalahan Registrasi',
      //   variant,
      //   solid: true,
      //   autoHideDelay: 10000,
      //   enableHtml: true,
      //   allowHtml: true,
      // })
      this.$swal({
        title: 'Pendaftaran Gagal!',
        html: message,
        icon: variant,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    async recaptchaReload() {
      await this.$recaptchaLoaded()

      const token = await this.$recaptcha('register')
      this.recaptcha = token
    },
    onCaptchaVerified(recaptchaToken) {
      this.resetCaptcha()
      this.recaptcha = recaptchaToken
      this.login()
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset()
    },
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          Authregister({
            full_name: this.userFullName,
            handphone: this.handphone,
            dob: this.dob,
            gender: this.gender,
            email: this.userEmail,
            password: this.password,
            password_confirmation: this.password,
            register_as: this.registerAs,
            pendidikan_terakhir: this.pendidikanTerakhir,
            provinsi: this.provinsi,
            kab_kota: this.kabKota,
            status_pernikahan: this.statusPernikahan,
            status_pekerjaan: this.statusPekerjaan,
            disabilitas: this.disabilitas,
            recaptcha: this.recaptcha,
          })
            .then(response => {
              if (response.data.status.code === '00') {
                if (this.applicationToken === '') {
                  this.$router.replace('login')
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Welcome ${this.userFullName || this.username}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: 'Anda telah berhasil mendaftar. Sekarang Anda dapat mulai masuk ke Aplikasi Kita Kerja!',
                        },
                      })
                    })
                } else {
                  this.$router.replace({ name: 'auth-login-token', params: { token: this.applicationToken } })
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Welcome ${this.userFullName || this.username}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: 'Anda telah berhasil mendaftar. Sekarang Anda dapat mulai masuk ke Aplikasi Kita Kerja!',
                        },
                      })
                    })
                }
              } else {
                this.recaptchaReload()
                this.makeToast('danger', response.data.data)
                this.errorsValidation = response.data.errors
              }
            })
            .catch(error => {
              // this.$refs.registerForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
